<template>
  <div>
    <br>
    <v-container
      fluid
      class=""
    >
      <v-row
        class="ml-5 mr-5"
      >
        <v-col
          cols="12"
          sm="4"
        >
          <h1 style="font-size: 25px; color: #013149" >{{ totalUsers }} Users</h1>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            v-model="search"
            dense
            label="Search"
            solo
            style="height: 60px"
            :append-icon="search && 'mdi-close'"
            append-outer-icon="mdi-magnify"
            @click:append-outer="selectedUsers = [], getUserInformation()"
            @click:append="selectedUsers = [], search = '', getUserInformation()"
            @click:clear="selectedUsers = [], getUserInformation()"
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <v-select
            label="Add columns"
            v-model="newSelectedColumns"
            :items="newColumns"
            :menu-props="{ maxHeight: '200', bottom: true, offsetY: true }"
            dense
            solo
            multiple
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text text-caption"
              >
                (+{{ newSelectedColumns.length - 1 }})
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="12"
          sm="2"
          align="end"
        >
          <user-filters
            ref="filters"
          ></user-filters>
        </v-col>
      </v-row>
    </v-container>        
    <v-snackbar
      v-model="alert"
      min-width="0"
    >
      <v-icon medium left>mdi-check-circle</v-icon>
      <!-- <v-icon v-else medium left>mdi-close-circle</v-icon> -->
      {{ snackbarText }}
    </v-snackbar>
    <div class="ml-8 mb-2" >
      <v-chip-group
        active-class="primary--text"
        column
      >
        <v-chip
          v-for="(value, key) in userFilters" :key="key"
          class="ml-2 mb-1"
          small
          label
          close
          :active="value.value.length > 0 ? true : false"
          @click:close="$refs.filters.cleanFilter(key)"
        >
          {{value.text}} {{ value.value }}
        </v-chip>
      </v-chip-group>
    </div>
    <v-data-table
      v-model="selectedUsers"
      :headers="headers"
      :items="userInformation"
      item-key="id"
      :single-select="singleSelect"
      checkbox-color="#00A9E5"
      show-select
      class="elevation-5 ml-10 mr-10"
      :options.sync="options"
      disable-pagination
      :hide-default-footer="true"
      multi-sort
      :loading="loading"
      loading-text="Loading... Please wait"     
    >
      <template v-slot:top v-if="selectedUsers.length > 0">
        <v-container
          fluid
        >
          <v-row
            class="pa-1"
            align="center"
          >
            <v-col
              cols="12"
              sm="2"
            >
              <div class="d-flex">
                <v-checkbox
                  class="d-flex align-center"
                  color="#00A9E5"
                  @change="unselectUsers()"
                  :input-value="selectedUsers.length > 0 ? true : false"
                >
                </v-checkbox>
                <h1
                  class="d-flex align-center"
                  style="font-size: small; color: #013149" 
                >
                  {{ 
                    selectAll === false? 
                      `${selectedUsers.length}/${totalUsers} users selected` 
                    : 
                      ` All ${totalUsers} selected`
                  }}
                </h1>
              </div>
              <v-btn
                v-if="selectedUsers.length === userInformation.length"
                class="text-none ml-1"
                dark
                small
                style="margin-top: -35px"
                color="black"
                @click="selectAll ? (selectAll = false, selectedUsers = []) : selectAll = true"
              >
                {{ selectAll ? "Clear selection" : `Select All ${totalUsers} users`}}
              </v-btn>
            </v-col>
            <v-col
              class="d-flex"
              cols="12"
              sm="auto"
              v-if="userRole === 'admin' && selectedUsers.length === 1"
            >
              <custom-menu
                color="#00A9E5"
                title="Set as admin"
                icon="mdi-menu-down"
                :vModel.sync="role"
                :options="['admin', 'staff', 'read_only']"
                :method="setUserAsAdmin"
                :disabled="role !== '' ? false : true"
                :loading="loading"
                buttonTitle="Save"
              ></custom-menu>
            </v-col>
            <v-col
              class="d-flex"
              cols="12"
              sm="auto"
              v-if="userRole === 'admin' && selectedUsers.length === 1"
            >
              <custom-menu
                color="#2D952C"
                title="User status"
                icon="mdi-menu-down"
                :vModel.sync="userStatus"
                :options="userStatusOptions"
                :method="updateUserSatus"
                :disabled="userStatus ? false : true"
                :loading="loading"
                buttonTitle="Save"
              ></custom-menu>
            </v-col>
            <v-col
              class="d-flex"
              cols="12"
              sm="auto"
              v-if="(userRole === 'admin' || userRole === 'staff') && selectedUsers.length === 1"
            >
              <custom-menu
                color="#DC143C"
                title="Email status"
                icon="mdi-menu-down"
                :vModel.sync="userEmailStatus"
                :options="userEmailOptions"
                :method="updateEmailStatus"
                :disabled="userEmailStatus ? false : true"
                :loading="loading"
                buttonTitle="Save"
              ></custom-menu>
            </v-col>
            <v-col
              class="d-flex"
              cols="12"
              sm="auto"
              v-if="userRole === 'admin' || userRole === 'staff'"
            >
              <custom-button
                title="Download in csv"
                color="#46C9FF"
                icon="mdi-download"
                :method="downloadInCsv"
                :loading="loading"
              ></custom-button>
            </v-col>
            <v-col
              class="d-flex"
              cols="12"
              sm="auto"
              v-if="userRole === 'admin' || userRole === 'staff'"
            >
              <user-emails 
                :selectedUsers="selectedUsers"
                :searchInput="search"
                :selectAll="selectAll"
              ></user-emails>
            </v-col>
            <v-col
              class="d-flex"
              cols="12"
              sm="auto"
            >
              <custom-button
                v-if="selectedUsers.length === 1"
                title="Copy email"
                color="#6B6F6B"
                icon="mdi-content-copy"
                :method="copyEmail"
              ></custom-button>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <v-avatar size="40px">
          <img
            v-if="item.image === '' || item.image === null"
            src='@/assets/No_user_profile.jpg'
            alt="profile_image"
          >
          <img
            v-else
            :src="item.image"
            alt="profile_image"
          >
        </v-avatar>
      </template>
      <template v-slot:[`item.tags`]="{ item }">
        <v-dialog
          width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="item.tags !== 0 ? '#00A9E5' : '#6B6F6B'"
              fab
              x-small
              dark
              v-bind="attrs"
              v-on="on"
              @click="getUserTags(item.id)"
            >
              {{ item.tags }}
            </v-btn>
          </template>
          <v-card>
            <h4 class="ml-4">User tags:</h4>
            <div v-if="loading" >
              <v-card-title class="d-flex justify-center aling-center">
                <v-progress-circular indeterminate color="grey"></v-progress-circular>
              </v-card-title>
            </div>
            <div v-else v-for="value in tags" :key="value.id">
              <v-card-title class="py-0 my-0">
                <span style="font-size: small;">{{ value.name }}:</span>
              </v-card-title>
              <v-chip-group
                class="ml-2"
                active-class="primary--text"
                column
              >
                <v-chip
                  v-for="(tag, index) in value.tags"
                  :key="index"
                >
                  {{ tag }}
                </v-chip>
              </v-chip-group>
            </div>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.organizations`]="{ item }">
        <v-dialog
          width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="item.organizations !== 0 ? '#00A9E5' : '#6B6F6B'"
              fab
              x-small
              dark
              v-bind="attrs"
              v-on="on"
              @click="getUserOrganizations(item.id)"
            >
              {{ item.organizations }}
            </v-btn>
          </template>
          <v-card>
            <h4 class="ml-4">User organizations:</h4>
            <div v-if="loading" >
              <v-card-title class="d-flex justify-center aling-center">
                <v-progress-circular indeterminate color="grey"></v-progress-circular>
              </v-card-title>
            </div>
            <div class="d-flex pa-4" v-else v-for="value in organizations" :key="value.id">
              <v-img
                max-width="100"
                max-height="100"
                :src="value.logo"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-card-title>
                <span style="font-size: small;">{{ value.name }}</span>
              </v-card-title>
            </div>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.user_types`]="{ item }">
        <v-dialog
          width="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="item.user_types !== 0 ? '#00A9E5' : '#6B6F6B'"
              fab
              x-small
              dark
              v-bind="attrs"
              v-on="on"
              @click="getUserTypes(item.id)"
            >
              {{ item.user_types }}
            </v-btn>
          </template>
          <v-card>
            <h4 class="ml-4">User types:</h4>
            <div v-if="loading" >
              <v-card-title class="d-flex justify-center aling-center">
                <v-progress-circular indeterminate color="grey"></v-progress-circular>
              </v-card-title>
            </div>
            <div v-else v-for="value in userTypes" :key="value.id">
              <v-card-title class="py-0 my-0">
                <span style="font-size: small;">{{ value.name ? value.name : " " }}</span>
              </v-card-title>
            </div>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.reset_password`]="{ item }">
        <reset-password
          :userFirtsName="item.first_name"
          :userLastName="item.last_name"
          :userEmail="item.email"
          :userRegistrationId="item.register_id"
        ></reset-password>
      </template>
      <template v-slot:[`item.email_status`]="{ item }">
        <v-chip
          class="short"
          :color="item.email_status === 'Opt-in' ? '#013149' : '#6B6F6B'"
          small
          label
          dark
        >
          {{ item.email_status }}
        </v-chip>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          class="short"
          :color="item.status === 'Enabled' ? '#2D952C' : '#6B6F6B'"
          small
          label
          dark
        >
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:[`item.account_created`]="{ item }">
        <div class="d-flex justify-center">
          <v-checkbox
            v-model="item.account_created"
            color="#2D952C"
            dense
            off-icon="mdi-close-box"
            readonly
          ></v-checkbox>
        </div>
      </template>
      <template v-slot:[`item.facebook`]="{ item }">
        <a @click="openLink(item.facebook)">{{ item.facebook }}</a>
      </template>
      <template v-slot:[`item.instagram`]="{ item }">
        <a @click="openLink(item.instagram)">{{ item.instagram }}</a>
      </template>
      <template v-slot:[`item.linkedin`]="{ item }">
        <a @click="openLink(item.linkedin)">{{ item.linkedin }}</a>
      </template>
      <template v-slot:[`item.twitter`]="{ item }">
        <a @click="openLink(item.twitter)">{{ item.twitter }}</a>
      </template>
      <template v-slot:[`item.yelp`]="{ item }">
        <a @click="openLink(item.yelp)">{{ item.yelp }}</a>
      </template>
      <template v-slot:[`item.website`]="{ item }">
        <a @click="openLink(item.website)">{{ item.website }}</a>
      </template>
    </v-data-table>
    <v-pagination
      v-model="page"
      :length="pageCount"
      total-visible="7"
      @input="handlePageChange"
    ></v-pagination>
  </div>
</template>

<script>
import store from '../../store';
import router from '../../router'
import { mapGetters, mapMutations } from 'vuex';

import Alert from '../../components/Alert.vue'
import CustomMenu from '../../components/CustomMenu.vue'
import CustomButton from '../../components/CustomButton.vue'
import CustomRadioSelect from '../../components/CustomRadioSelect.vue'
import UserEmails from './components/UserEmails.vue'
import ResetPassword from './components/ResetPassword.vue'
import UserFilters from './components/UserFilters.vue'

import {userParameters} from '../../utils/userParameters.js'

import {
  UpdateUserInformation, 
  GetUserTags, 
  GetUserOrganizations,
  GetUserTypes,
  GetInformationToExportToCSV
} from '../../api/user';
import { UpdateAdminUser, CurrentAdminInformation } from '../../api/admin';

export default {
  
  components: {
    Alert,
    CustomMenu,
    CustomButton,
    CustomRadioSelect,
    UserEmails,
    ResetPassword,
    UserFilters
  },

  data () {
    return {
      loading: true,
      alert: false,
      snackbarText: "",
      options: {},
      page: 1,
      pageCount: 0,
      totalUsers: 0,
      role: '',
      userInformation: [],
      sentEmailAlert: false,
      singleSelect: false,
      search: '',
      newSelectedColumns: [],
      userEmailOptions: ["Opt-in", "Opt-out"],
      userEmailStatus: "",
      userStatusOptions: ["Enabled", "Disabled"],
      userStatus: "",
      selectAll: false,
      selectedUsers: [],
      status: "",
      emailSent: false,
      tags: [],
      organizations: [],
      userTypes: [],
    }
  },

  mounted () {
    this.getUserInformation()
    CurrentAdminInformation().then( response => {
      this.setUserRole(response.data.role)
      if (response.data.is_active === false) {
        store.dispatch('user/logout');
        router.push('/login');
      }
    })
  },

  methods: {

    ...mapMutations("user", ['setUserRole']),

    getUserInformation () {
      this.loading = true
      let params = userParameters(this.userFilters)
      params["organization"] = this.userOrganization
      params["page"] = this.page
      params["search_bar"] = this.search

      this.$store.dispatch('user/userInformation', params).then( response => {
        this.userInformation = response.results
        this.totalUsers = response.total_results.toLocaleString()
        this.pageCount = response.total_pages
        this.loading = false
      }).catch( () => {
        this.loading = false
      })
    },

    updateEmailStatus () {
      this.loading = true

      this.userInformation.forEach( user => {
        for ( const u of this.selectedUsers) {
          if (user.email === u.email) {
            UpdateUserInformation(user.id, {email_status: this.userEmailStatus === "Opt-in" ? false : true, is_active: false}
            ).then( () => {
              user.email_status = this.userEmailStatus
              this.loading = false
              this.alert = true
              this.snackbarText = "Email status change successfully"
            }).catch( () => {
              this.loading = false
              this.alert = true
              this.snackbarText = "Email status change failed"
            })
          }
        }
      })
    },

    updateUserSatus () {
      this.loading = true

      this.userInformation.forEach( user => {
        for ( const u of this.selectedUsers) {
          if (user.email === u.email) {
            UpdateUserInformation(user.id, {is_active: this.userStatus === "Enabled" ? true : false}
            ).then( () => {
              user.status = this.userStatus
              this.loading = false
              this.alert = true
              this.snackbarText = "User status change successfully"
            }).catch( () => {
              this.loading = false
              this.alert = true
              this.snackbarText = "User status change failed"
            })
          }
        }
      })
    },

    setUserAsAdmin () {
      this.loading = true
      const data = {
        is_admin: true,
        role: this.role
      }
      const userId = this.selectedUsers[0].id
      UpdateAdminUser(userId, data).then( () => {
        this.loading = false
        this.alert = true
        this.snackbarText = "User set as admin successfully"
      }).catch( () => {
        this.loading = false
        this.alert = true
        this.snackbarText = "Set user as admin failed"
      })
    },

    async downloadInCsv () {
      this.loading = true
      
      let userInformation = []
      let csv = []

      if (this.selectAll) {
        let params = userParameters(this.userFilters)
        params["organization"] = this.userOrganization
        params["search_bar"] = this.search
  
        await GetInformationToExportToCSV(params).then( response => {
          userInformation = response.data
        }).catch( () => csv = [{}])
      } else {
        userInformation = this.selectedUsers
      }

      csv = userInformation.map( user => {
        return {
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          email_status: user.email_status,
          country: user.country,
          state: user.state,
          city: user.city,
          profession: user.profession,
          specialty: user.specialty,
          facebook: user.facebook,
          instagram: user.instagram,
          linkedin: user.linkedin,
          twitter: user.twitter,
          yelp: user.yelp,
          website: user.website,
          phone: user.phone,
          take_break: user.take_break,
          user_blocked: user.user_blocked,
          searches: "user_searches" in user ? user.user_searches : user.searches,
          meetings: user.scheduled_meetings,
          account_created: user.account_created,
          status: user.status,
          created_at: user.created_at,
        }
      })     

      const csvContent = "data:text/csv;charset=utf-8," +
        Object.keys(csv[0]).map( key => key ).join(",") +
        "\n" +
        csv.map( row => Object.values(row).join(",") ).join("\n")

      const encodedUri = encodeURI(csvContent).replaceAll('#', '%23');
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "users.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.loading = false
      this.alert = true
      this.snackbarText = "CSV generated successfully"
    },

    copyEmail () {
      if (this.selectedUsers.length === 1) {
        const email = this.selectedUsers[0].email
        const el = document.createElement('textarea');
        el.value = email;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.alert = true
        this.snackbarText = "Email copied"
      }
    },

    getUserTags (id) {
      this.loading = true

      GetUserTags(id).then( response => {
        this.loading = false
        this.tags = response.data
      }).catch( () => {
        this.loading = false
      })
    },

    getUserOrganizations (id) {
      this.loading = true

      GetUserOrganizations(id).then( response => {
        this.loading = false
        this.organizations = response.data
      }).catch( () => {
        this.loading = false
      })
    },

    getUserTypes (id) {
      this.loading = true

      GetUserTypes(id).then( response => {
        this.loading = false
        this.userTypes = response.data
      }).catch( () => {
        this.loading = false
      })
    },

    openLink (item) {
      window.open(item, '_blank')
    },

    handlePageChange (value) {
      this.loading = true
      this.selectedUsers = []
      this.page = value
      this.getUserInformation()
    },
    
    unselectUsers () {
      this.selectedUsers = []
    },

  },

  watch: {
    userOrganization (newOrganization, oldOrganization) {
      if (newOrganization !== oldOrganization) {
        this.search = ""
        this.selectedUsers = []
        this.getUserInformation()
      }
    },

    userFilters: {
      handler () {
        this.selectedUsers = []
        this.getUserInformation()
      },
      deep: true
    },

    selectedUsers () {
      this.selectedUsers.length !== this.userInformation.length && (this.selectAll = false)
    }
  },

  computed: {

    ...mapGetters({
      userOrganization: "user/getUserOrganization",
      userRole: "user/getUserRole",
      userFilters: "user/getUserFilters"
    }),

    headers () {

      let headers = [
        { text: '', value: 'image', align: 'center', width: '150' },
        { text: 'First name', align: 'start', sortable: true, value: 'first_name', width: '150' },
        { text: 'Last name', value: 'last_name', width: '150' },
        { text: 'Email', value: 'email', width: '150' },
        { text: 'Email status', value: 'email_status', width: '150' },
        { text: 'Status', value: 'status', width: '150' },
        { text: 'Created at', value: 'created_at', width: '150' },
        { text: 'Account created', value: 'account_created', align: 'center', width: '150' },
        { text: 'Reset password', value: 'reset_password', align: 'center', width: '150' },
      ]

      for (let selected of this.newSelectedColumns) {
        for (let column of this.newColumns) {
          column = column.toLocaleLowerCase().replace(/ /g, "_")
          if (selected.toLocaleLowerCase().replace(/ /g, "_").includes(column)) {
            headers.splice(headers.length - 5, 0, { 
              text: selected.replace(/_/g," "), value: column, align: 'center', width: '200' 
            })
          }
        }
      }

      if (this.userRole === "staff") {
        headers = headers.filter(header => {
          return header.value !== "reset_password"
        })
      } else if (this.userRole === "read_only" || this.userRole === "") {
        headers = headers.filter(header => {
          return header.value !== "email" && header.value !== "reset_password" && header.value !== "phone"
        })
      }

      return headers
    },

    newColumns () {
      let columns = [
        "Id", "Profession", "Specialty", "Facebook", "Instagram", "Linkedin", 
        "Twitter", "Yelp", "Website", "Mates", "Scheduled meetings", "User searches", "Country",
        "State", "City", "Phone", "Rating", "Take break", "Never sent meeting request", "Tags", 
        "Organizations", "User types"
      ]

      if (this.userRole === "read_only") {
        columns = columns.filter(column => column !== "Phone" )
      }

      return columns
    }
  }
}
</script>

<style >
  .short {
    width:70px;
    justify-content: center;
  }

  .list-item {
    font-size: 13px !important;
  }

  .v-radio .v-label {
    font-size: 13px !important;
  }

  v-btn .v-label {
    font-size: 13px !important;
  }

  .templates-container {
    overflow-y: scroll;
  }
</style>
