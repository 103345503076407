<template>
  <v-row
    align="center"
  >
    <v-btn
      class="text-none ml-2"
      dark
      :loading="loading"
      :color="color"
      @click="method"
    >
      <v-icon medium left>
        {{ icon }}
      </v-icon>
      {{ title }}
    </v-btn>
  </v-row>
</template>

<script>

export default {
  props: {
    title: String,
    icon: String,
    method: Function,
    loading: Boolean,
    color: String,
  },
}
</script>

<style >

  v-btn {
    font-size: 13px !important;
  }
</style>
