<template>
  <div>
    <alert
      v-if="resetPasswordAlert"
      :vModel.sync="resetPasswordAlert"
      :description="passwordWasReset ? 'Password was successfully reset' : 'There was an error resetting the password, please try again later'"
      :disabled="true"
    ></alert>
    <v-dialog
      v-model="resetPasswordMenu"
      width="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          fab
          small
          v-bind="attrs"
          v-on="on"
        >
          ...
        </v-btn>
      </template>
      <v-card class="pa-2">
        <h5 class="pa-2">Are you sure you want to reset the password for user {{ userFirtsName }} {{ userLastName }} ?</h5>
        <v-card-actions class="justify-space-around">
          <v-btn
            class="text-none"
            width="80"
            small
            dense
            dark
            color="#6B6F6B"
            @click="resetPasswordMenu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            class="text-none"
            width="80"
            small
            dense
            dark
            color="primary"
            :loading="loading"
            @click="resetPassword"
          >
            Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Alert from '../../../components/Alert.vue'

import {
  RequestPasswordReset,
  GetPasswordResetToken,
  SetPasswordReset,
} from '../../../api/user';

export default {

  components: {
    Alert,
  },
  
  props: {
    userFirtsName: String,
    userLastName: String,
    userEmail: String,
    userRegistrationId: String
  },

  data () {
    return {
      loading: false,
      resetPasswordMenu: false,
      resetPasswordAlert: false,
      passwordWasReset: false,
    }
  },

  methods: {

    resetPassword () {
      this.loading = true

      RequestPasswordReset({"email": `${this.userEmail}`}).then( () => {
        GetPasswordResetToken(this.userRegistrationId).then( response => {
          const temporalPassword = ":" + "Mate" + (Math.random() + 1).toString(36).substring(6)
          const encodedData = btoa(temporalPassword);
          const headers = {
            "Authorization": "Basic " + encodedData,
          }
          SetPasswordReset({"token": response.data.reset_password_token}, headers).then( () => {
            this.loading = false
            this.resetPasswordMenu = false
            this.passwordWasReset = true
            this.resetPasswordAlert = true
          }).catch( () => {
            this.loading = false
            this.resetPasswordMenu = false
            this.passwordWasReset = false
            this.resetPasswordAlert = true
          })
        }).catch( () => {
          this.loading = false
          this.resetPasswordMenu = false
          this.passwordWasReset = false
          this.resetPasswordAlert = true
        })
      }).catch( () => {
        this.loading = false
        this.resetPasswordMenu = false
        this.passwordWasReset = false
        this.resetPasswordAlert = true
      })
    },

  },
}
</script>
