<template>
  <v-menu
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="color"
        dark
        class="text-none list-item"
        v-bind="attrs"
        v-on="on"
      >
        {{ title }}
        <v-icon small> {{ icon }} </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item>
        <template v-slot:default="{}">
          <v-list-item-action class="mt-0 mb-0">
            <v-radio-group
              v-model="propModel"
              dense
            >
              <v-radio
                v-for="option in options"
                :key="option"
                :label="option"
                :value="option"
                @mouseup="reset(option)"
              ></v-radio>
            </v-radio-group>
          </v-list-item-action>
        </template>
      </v-list-item>
      <v-btn
        class="text-none ml-2"
        small
        text
        @click="method"
        :disabled="disabled"
        :loading="loading"
      >
        {{ buttonTitle }}
      </v-btn>
    </v-list>
  </v-menu>
</template>

<script>

export default {
  props: {
    color: String,
    title: String,
    icon: String,
    disabled: Boolean,
    loading: Boolean,
    vModel: String,
    options: Array,
    method: Function,
    buttonTitle: String,
  },

  methods: {
    reset (value) {
      if (this.searches === value) {
        this.$nextTick(() => {
          this.searches = ""
        })
      }
    },
  },

  computed: {
    propModel: {
      get () { return this.vModel },
      set (value) { this.$emit('update:vModel', value) },
    },
  },
}
</script>
