<template>
  <v-menu
    offset-y
    v-model="filterMenu"
    max-height="400"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="text-none"
        v-bind="attrs"
        v-on="on"
      >
        More filters
      </v-btn>
    </template>
    <v-list>
      <v-list-group
        :value="false"
      >
        <template v-slot:activator>
          <v-list-item-content class="list-item">
            Users added within selected date range
          </v-list-item-content>
        </template>
        <v-list-item
        >
          <v-list-item-content>
            <div class="d-flex ma-0 mb-0">
              <v-text-field
                class="list-item"
                v-model="creationDates[0]"
                :error-messages="creationDates[0] ? '' : 'Please select a date'"
                label="From"
                type="date"
                outlined
                autofocus
                dense
              ></v-text-field>
              <v-text-field
                class="list-item"
                v-model="creationDates[1]"
                :error-messages="creationDates[1] ? '' : 'Please select a date'"
                label="To"
                type="date"
                background-color="#f5f3f2"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-group
        :value="false"
      >
        <template v-slot:activator>
          <v-list-item-content class="list-item">Number of user searches</v-list-item-content>
        </template>
        <custom-radio-select
          :vModel.sync="searches"
          :options="userSearchOptions"
        ></custom-radio-select>
      </v-list-group>
      <v-list-group
        :value="false"

      >
        <template v-slot:activator>
          <v-list-item-content class="list-item">Number of scheduled meetings</v-list-item-content>
        </template>
        <custom-radio-select
          :vModel.sync="scheduledMeetings"
          :options="userSearchOptions"
        ></custom-radio-select>
      </v-list-group>
      <v-list-group
        :value="false"  
      >
        <template v-slot:activator>
          <v-list-item-content class="list-item">
            Users with meetings in a date range
          </v-list-item-content>
        </template>
        <v-list-item
        >
          <v-list-item-content>
            <div class="d-flex ma-0 mb-0">
              <v-text-field
                class="list-item"
                v-model="meetingDates[0]"
                :error-messages="meetingDates[0] ? '' : 'Please select a date'"
                label="From"
                type="date"
                outlined
                autofocus
                dense
              ></v-text-field>
              <v-text-field
                class="list-item"
                v-model="meetingDates[1]"
                :error-messages="meetingDates[1] ? '' : 'Please select a date'"
                label="To"
                type="date"
                background-color="#f5f3f2"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-group
        :value="false"
      >
        <template v-slot:activator>
          <v-list-item-content class="list-item">Users who have been blocked</v-list-item-content>
        </template>
        <custom-radio-select
          :vModel.sync="blockedUsers"
          :options="noMeetingsOptions"
        ></custom-radio-select>
      </v-list-group>
      <v-list-group
        :value="false"  
      >
        <template v-slot:activator>
          <v-list-item-content class="list-item">Memberships expiring range</v-list-item-content>
        </template>
        <v-list-item
        >
          <v-list-item-content>
            <div class="d-flex ma-0 mb-0">
              <v-text-field
                class="list-item"
                v-model="membershipExpireDates[0]"
                :error-messages="membershipExpireDates[0] ? '' : 'Please select a date'"
                label="From"
                type="date"
                outlined
                autofocus
                dense
              ></v-text-field>
              <v-text-field
                class="list-item"
                v-model="membershipExpireDates[1]"
                :error-messages="membershipExpireDates[1] ? '' : 'Please select a date'"
                label="To"
                type="date"
                background-color="#f5f3f2"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-group
        :value="false"
      >
        <template v-slot:activator>
          <v-list-item-content class="list-item">{{ organizationTags.name }}</v-list-item-content>
        </template>
        <v-select
          class="mx-6 font-class-name"
          v-model="selectedOrganizationTag"
          :items="organizationTags.tags"
          item-text="name"
          :menu-props="{ maxHeight: '200', bottom: true, offsetY: true }"
          dense
        ></v-select>
      </v-list-group>
      <v-list-group
        :value="false"
      >
        <template v-slot:activator>
          <v-list-item-content class="list-item">{{ socialActivitiesTags.name }}</v-list-item-content>
        </template>
        <v-select
          class="mx-6 font-class-name"
          v-model="selectedSocialActivitiesTag"
          :items="socialActivitiesTags.tags"
          item-text="name"
          :menu-props="{ maxHeight: '200', bottom: true, offsetY: true }"
          dense
        ></v-select>
      </v-list-group>
      <v-list-group
        :value="false"
      >
        <template v-slot:activator>
          <v-list-item-content class="list-item">
            {{ communityActivitiesTags.name }}
          </v-list-item-content>
        </template>
        <v-select
          class="mx-6 font-class-name"
          v-model="selectedCommunityActivities"
          :items="communityActivitiesTags.tags"
          item-text="name"
          :menu-props="{ maxHeight: '200', bottom: true, offsetY: true }"
          dense
        ></v-select>
      </v-list-group>
      <v-list-group
        :value="false"
      >
        <template v-slot:activator>
          <v-list-item-content class="list-item"> Professions </v-list-item-content>
        </template>
        <v-select
          class="mx-6 font-class-name"
          v-model="selectedProfession"
          :items="professions"
          item-text="name"
          :menu-props="{ maxHeight: '200', bottom: true, offsetY: true }"
          dense
          @input="changeProfession"
        ></v-select>
        <v-select
          class="mx-6 font-class-name"
          label="Specialty"
          v-model="selectedSpecialty"
          :items="specialties"
          item-text="name"
          :disabled="specialtyDisabled"
          :menu-props="{ maxHeight: '200', bottom: true, offsetY: true }"
          dense
        ></v-select>
      </v-list-group>
      <v-list-group
        :value="false"
      >
        <template v-slot:activator>
          <v-list-item-content class="list-item">Email status</v-list-item-content>
        </template>
        <custom-radio-select
          :vModel.sync="emailStatus"
          :options="userEmailOptions"
        ></custom-radio-select>
      </v-list-group>
      <v-list-group
        :value="false"
      >
        <template v-slot:activator>
          <v-list-item-content class="list-item">Status</v-list-item-content>
        </template>
        <custom-radio-select
          title:="Status"
          :vModel.sync="status"
          :options="statusOptions"
        ></custom-radio-select>
      </v-list-group>
      <div class="d-flex justify-end">
        <v-btn
          class="text-none"
          small
          text
          @click="cleanAllFilters(false)"
        >
          Clear all fliters
        </v-btn>
        <v-btn
          class="text-none ml-2 mr-5"
          small
          color="primary"
          @click="updateFilters"
        >
          Apply
        </v-btn>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import CustomRadioSelect from '../../../components/CustomRadioSelect.vue'

import {
  GetProfessions, 
  GetSpecialties,
  GetTagTypes
} from '../../../api/user/filters';


export default {

  components: {
    CustomRadioSelect,
  },

  data () {
    return {
      filterMenu: false,
      searches: "",
      creationDates: [],
      meetingDates: [],
      scheduledMeetings: "",
      noMeetings: "",
      blockedUsers: "",
      emailStatus: "",
      status: "",
      filters: {
        usersAddedBetween: {value: [], text: "Users added between:"},
        numberOfSearches: {value: "", text: "Number of searches:"},
        scheduledMeetings: {value: "", text: "Scheduled meetings:"},
        neverScheduledMeetings: {value: "", text: "Never scheduled a meeting:"},
        usersWithMeetingsBetween: {value: [], text: "Users with meetings between:"},
        UsersWhoHaveBeenBlocked: {value: "", text: "Users who have been blocked:"},
        emailStatus: {value: "", text: "Email status:"},
        userStatus: {value: "", text: "User status:"},
        membershipExpire: {value: [], text: "Memberships expiring between:"},
        organizationTag: {value: "", text: "Organization tag:"},
        socialActivitiesTag: {value: "", text: "Social activities tag:"},
        communityActivitiesTag: {value: "", text: "Community activities tag:"},
        profession: {value: "", text: "Profession:"},
        specialty: {value: "", text: "Specialty:"},
      },
      userEmailOptions: ["Opt-in", "Opt-out"],
      noMeetingsOptions: ["Yes", "No"],
      statusOptions: ["Enabled", "Disabled"],
      userSearchOptions: ["0", "1 - 3", "4 - 10", "11 - 20", "20 - 30", "More than 30"],
      statusOptions: ["Enabled", "Disabled"],
      membershipExpireDates: [],
      specialtyDisabled: true,
      professions: [],
      selectedProfession: "",
      specialties: [],
      selectedSpecialty: "",
      organizationTags: [],
      selectedOrganizationTag: "",
      socialActivitiesTags: [],
      selectedSocialActivitiesTag: "",
      communityActivitiesTags: [],
      selectedCommunityActivities: "",
    }
  },

  mounted () {
    this.getProfessions()
    this.getTagTypes()
  },

  methods: {

    ...mapMutations("user", ['setUserFilters']),

    getProfessions () {
      let params = {
        "organization": this.userOrganization
      }
      GetProfessions(params).then( response => {
        this.professions = response.data
      })
    },

    getTagTypes () {
      let params = {
        "organization": this.userOrganization
      }
      GetTagTypes(params).then(response => {
        if (response.data.length === 3) {
          this.organizationTags = response.data[0]
          this.socialActivitiesTags = response.data[1]
          this.communityActivitiesTags = response.data[2]
        }
      })
    },

    updateFilters () {
      this.filterMenu = false
      if (this.creationDates.length === 2) {
        this.filters.usersAddedBetween.value = []
        this.filters.usersAddedBetween.value.push(...this.creationDates)
      }
      if (this.meetingDates.length === 2) {
        this.filters.usersWithMeetingsBetween.value = []
        this.filters.usersWithMeetingsBetween.value.push(...this.meetingDates)
      }
      if (this.membershipExpireDates.length === 2) {
        this.filters.membershipExpire.value = []
        this.filters.membershipExpire.value.push(...this.membershipExpireDates)
      }
      this.filters.numberOfSearches.value = this.searches
      this.filters.scheduledMeetings.value = this.scheduledMeetings
      this.filters.neverScheduledMeetings.value = this.noMeetings
      this.filters.UsersWhoHaveBeenBlocked.value = this.blockedUsers
      this.filters.emailStatus.value = this.emailStatus
      this.filters.userStatus.value = this.status

      this.filters.organizationTag.value = this.selectedOrganizationTag
      this.filters.socialActivitiesTag.value = this.selectedSocialActivitiesTag
      this.filters.communityActivitiesTag.value = this.selectedCommunityActivities
      this.filters.profession.value = this.selectedProfession
      this.filters.specialty.value = this.selectedSpecialty

      this.setUserFilters(this.filters)
    },

    cleanAllFilters (organizationChange) {
      this.filterMenu = false
      this.searches= ""
      this.creationDates = []
      this.meetingDates = []
      this.scheduledMeetings = ""
      this.noMeetings = ""
      this.emailStatus = ""
      this.status = ""
      this.blockedUsers = ""
      this.membershipExpireDates = [],
      this.selectedOrganizationTag = ""
      this.selectedSocialActivitiesTag = ""
      this.selectedCommunityActivities = ""
      this.selectedProfession = ""
      this.selectedSpecialty = ""
      this.specialtyDisabled = true

      for (const [key, value] of Object.entries(this.filters)) {
        if (Array.isArray(value)) {
          this.filters[key].value = []
        } else {
          this.filters[key].value = ""
        }
      }
      !organizationChange && this.updateFilters()
    },  
    
    cleanFilter (filter) {
      filter === "usersAddedBetween" && (this.creationDates = [], this.filters.usersAddedBetween.value = []) 
      filter === "usersWithMeetingsBetween" && (this.meetingDates = [], this.filters.usersWithMeetingsBetween.value = [])
      filter === "numberOfSearches" && (this.searches = "")
      filter === "scheduledMeetings" && (this.scheduledMeetings = "")
      filter === "neverScheduledMeetings" && (this.noMeetings = "")
      filter === "UsersWhoHaveBeenBlocked" && (this.blockedUsers = "")
      filter === "emailStatus" && (this.emailStatus = "")
      filter === "userStatus" && (this.status = "")
      filter === "membershipExpire" && (this.membershipExpireDates = [], this.filters.membershipExpire.value = [])
      filter === "organizationTag" && (this.selectedOrganizationTag = "")
      filter === "socialActivitiesTag" && (this.selectedSocialActivitiesTag = "")
      filter === "communityActivitiesTag" && (this.selectedCommunityActivities = "")
      filter === "profession" && (this.selectedProfession = "", this.selectedSpecialty = "", this.specialtyDisabled = true)
      filter === "specialty" && (this.selectedSpecialty = "")

      this.updateFilters()
    },

    changeProfession(profession) {
      if (profession !== "") {
        this.selectedSpecialty = ""
        this.specialtyDisabled = false
        const data = {
          profession_name: profession
        }
        GetSpecialties(data).then( response => {
          this.specialties = response.data
        })
      }
    },
   
  },

  watch: {
    userOrganization (newOrganization, oldOrganization) {
      if (newOrganization !== oldOrganization) {
        this.cleanAllFilters(true)
        this.getProfessions()
        this.getTagTypes()
      }
    },
  },

  computed: {
    ...mapGetters({
      userOrganization: "user/getUserOrganization",
    }),
  }
  
}
</script>

<style >
  .font-class-name * {
    font-size: 13px;
  }
</style>
