<template>
  <v-menu
    v-model="menu"
    offset-y
    bottom
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
      <v-list dense width="250px">
        <v-list-item style="height: 60px">
          <v-list-item-content class="text-center">
            <v-list-item-subtitle > {{ userEmail }} </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item-group
          v-model="selectedDrawer"
          color="primary"
          v-if="userRole === 'admin'"
        >
          <v-list-item
            v-for="(drawer, i) in drawers"
            :key="i"
            :to="drawer.path"
          >
            <v-list-item-icon>
              <v-icon v-text="drawer.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="drawer.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item
          v-if="isSuperuser"
          @click.stop="dialog = true, menu = false"
        >         
          <v-list-item-icon>
            <v-icon>mdi-autorenew</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Change organization </v-list-item-title>
          </v-list-item-content>
        </v-list-item>  
        <v-dialog
          v-model="dialog"
          scrollable
          max-width="400px"
        >
          <v-card>
            <v-card-title class="justify-center">Change organization</v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 300px;">
              <v-container v-if="loading" class="fill-height">
                <v-row>
                  <v-col
                    class="d-flex justify-center align-center"
                  >
                    <v-progress-circular indeterminate color="grey"></v-progress-circular>
                  </v-col>
                </v-row>
              </v-container>
              <v-radio-group
                v-else
                v-model="selectedOrganization"
                column
              >
                <v-list-item
                  v-for="organization in organizations"
                  :key="organization.id"
                  class="grow"
                >
                  <v-list-item-avatar color="white">
                    <v-img
                      contain
                      alt=""
                      :src="organization.id === '' ? require('@/assets/Mate_logo.jpeg') : organization.logo"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{organization.name}}</v-list-item-title>
                  </v-list-item-content>
                  <v-row
                    align="center"
                    justify="end"
                  >
                    <v-radio
                      label=""
                      :value="organization.id"
                      on-icon="mdi-check-circle"
                    ></v-radio>
                  </v-row>
                </v-list-item>
              </v-radio-group>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-center">
              <v-btn
                color="blue darken-1"
                text
                @click="setOrganization"
                :disabled="disabled"
              >
                Select
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <br>
        <v-list-item
          link
          @click="logout"
        >
          <v-list-item-icon>
            <v-icon >mdi-exit-to-app</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title >Log out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
  </v-menu>  
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import store from '../store';

import { GetOrganizations } from '../api/user';

export default {
  
  data () {
    return {
      loading: false,
      isSuperuser: false,
      menu: false,
      disabled: true,
      selectedOrganization: "",
      selectedDrawer: 0,
      dialog: false,
      drawers: [
        { name: 'Home', icon: 'mdi-view-dashboard', path: '/' },
        { name: 'Admins', icon: 'mdi-account-supervisor', path: '/admins' },
        { name: 'Statistics', icon: 'mdi-chart-bar', path: '/statistics' },
      ],
      organizations: []
    }
  },
  
  mounted () {
    this.isSuperuser = (this.userIsSuperuser === 'true')
    this.selectedOrganization = this.userOrganization
    this.getOrganizations()
  },

  watch: {
    dialog (val) {
      val && this.getOrganizations()
      val || (this.selectedOrganization = this.userOrganization)
    },

    selectedOrganization (val) {
      if (this.userOrganization !== val) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    }
  },

  methods: {

    ...mapMutations("user", ['setUserOrganization', 'setUserFilters']),

    getOrganizations () {
      this.loading = true
      GetOrganizations().then( response => {
        this.organizations = response.data.results
        this.organizations.unshift({"name": "Mate", "id": "", "logo": "../assets/No_user_profile.jpg" })
        this.loading = false
      }).catch( () => {
        this.loading = false
      })
    },

    setOrganization () {
      this.dialog = false
      this.disabled = true
      this.setUserFilters({})
      this.setUserOrganization(this.selectedOrganization)
    },

    logout () {
      store.dispatch('user/logout')
      this.$router.push('/login')
    },
  },

  computed: {
    ...mapGetters({
      userEmail: "user/getUserEmail",
      userOrganization: "user/getUserOrganization",
      userIsSuperuser: "user/getUserIsSuperuser",
      userRole: "user/getUserRole"
    })
  },
}
</script>
