<template>
  <v-app
    style="background-color: #f5f5f5"
  >
    <v-app-bar
      app
      color="#00A9E5"
      dark
      v-if="getIsAuthenticated"
    >
      <v-container fluid>
        <v-row justify="space-between">
          <router-link to="/">
            <v-img
              alt="Favicon Logo"
              class="shrink mr-2"
              contain
              src="./assets/Mate_negative_logo.png"
              transition="scale-transition"
              width="80"
            />
          </router-link>
          <navigation-drawers-view></navigation-drawers-view>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import NavigationDrawersView from './views/NavigationDrawersView.vue';

export default {

  components: {
    NavigationDrawersView
  },

  name: 'App',

  computed: {
    ...mapGetters("user", ["getIsAuthenticated"])
  },
};
</script>
