import Vue from 'vue'
import VueRouter from 'vue-router'
import UserTableView from '../views/UserManagement/UserTableView.vue'
import store from '../store'

Vue.use(VueRouter)

const isAuthenticated = () => {
  return store.getters['user/getIsAuthenticated']
}

const userRole = () => {
  return store.getters['user/getUserRole']
}

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Authentication/LoginView.vue'),
    beforeEnter: (to, from, next) => {
      if(isAuthenticated()) return next("/")
      next()
    }
  },
  {
    path: '/',
    name: 'userInformation',
    component: UserTableView,
    beforeEnter: (to, from, next) => {
      if(!isAuthenticated()) return next("/login")
      next()
    } 
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('../views/statistics/ChartsView.vue'),
    beforeEnter: (to, from, next) => {
      if(!isAuthenticated()) return next("/login")
      next()
    } 
  },
  {
    path: '/admins',
    name: 'admins',
    component: () => import('../views/Admins/AdminTableView.vue'),
    beforeEnter: (to, from, next) => {
      if(!isAuthenticated()) return next("/login")
      else if(isAuthenticated() && userRole() !== "admin") return next("/")
      next()
    } 
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../views/NotFoundView.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
