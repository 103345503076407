import request from '../base';

export function CreateAdminUser(data) {
  return request({
    url: 'administrative/admin_user/',
    method: 'post',
    data,
  })
}

export function UpdateAdminUser(userId, data) {
  return request({
    url: `administrative/admin_user/${userId}/`,
    method: 'patch',
    data,
  })
}

export function GetAdminUser(data) {
  return request({
    url: 'administrative/admin_user/',
    method: 'get',
    params: data,
  })
}

export function CurrentAdminInformation(data) {
  return request({
    url: 'administrative/admin_user/current_admin_information/',
    method: 'get',
    params: data,
  })
}
