export function userParameters(filters) {
  let params = {}
  if (Object.keys(filters).length > 0) {

    filters?.usersAddedBetween?.value.length === 2 && (
      params["creation_date"] = filters.usersAddedBetween.value[0],
      params["creation_date_end"] = filters.usersAddedBetween.value[1]
    )
    filters?.numberOfSearches?.value !== "" && (params["user_searches"] = filters.numberOfSearches.value)
    filters?.neverScheduledMeetings?.value !== "" && (params["users_without_meeting_requests"] = filters.neverScheduledMeetings.value)
    filters?.scheduledMeetings?.value !== "" && (params["scheduled_meetings"] = filters.scheduledMeetings.value)  
    filters?.usersWithMeetingsBetween?.value.length === 2  && (
      params["meeting_date"] = filters.usersWithMeetingsBetween.value[0], 
      params["meeting_date_end"] = filters.usersWithMeetingsBetween.value[1]
    )
    filters?.UsersWhoHaveBeenBlocked?.value !== "" && (params["user_blocked"] = filters.UsersWhoHaveBeenBlocked.value)
    filters?.membershipExpire?.value.length === 2  && (
      params["expiration_date"] = filters.membershipExpire.value[0], 
      params["expiration_date_end"] = filters.membershipExpire.value[1]
    )
    filters?.emailStatus?.value !== "" && (params["opted_out_email"] = filters.emailStatus.value)
    filters?.userStatus?.value !== "" && (params["is_active"] = filters.userStatus.value)
    filters?.profession?.value !== "" && (params["profession"] = filters.profession.value)
    filters?.specialty?.value !== "" && (params["specialty"] = filters.specialty.value)
    filters?.organizationTag?.value !== "" && (params["organization_tag"] = filters.organizationTag.value)
    filters?.socialActivitiesTag?.value !== "" && (params["social_activities_tag"] = filters.socialActivitiesTag.value)
    filters?.communityActivitiesTag?.value !== "" && (params["community_activities_tag"] = filters.communityActivitiesTag.value)
  }
  
  return params
}
