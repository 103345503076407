<template>
  <v-list-item>
    <template v-slot:default="{}">
      <v-list-item-action class="mt-0">
        <v-radio-group
          v-model="propModel"
          dense
        >
          <v-radio
            v-for="option in options"
            :key="option"
            :label="option"
            :value="option"
          ></v-radio>
        </v-radio-group>
      </v-list-item-action>
    </template>
  </v-list-item>
</template>

<script>

export default {
  props: {
    title: String,
    options: Array,
    vModel: String,
  },

  computed: {
    propModel: {
      get () { return this.vModel },
      set (value) { this.$emit('update:vModel', value) },
    },
  },

}
</script>
