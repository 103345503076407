import axios from 'axios';
import store from '../../store';
import router from '../../router'
import Cookies from 'js-cookie';

const base = process.env.VUE_APP_BASE_URL

const api = axios.create({
  baseURL: base,
  headers: {}
})

api.interceptors.request.use( config => {
    const token = Cookies.get('accessToken');
    if (token && !config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, 
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use( (response) => {
    return response
  }, 
  async error => {
    if (error.config && error.config.url.includes('login_web')) {
      return axios.request(error.config);
    }
    else if (error.response.status === 401 && error.response?.data.detail === "Given token not valid for any token type") {
      const token = await updateToken();
      error.config.headers.Authorization = `Bearer ${token}`;
      const response = await axios.request(error.config);
      return Promise.resolve(response);
    }
    else if (error.response.status === 401 && error.response?.data.detail === "User is inactive") {
      store.dispatch('user/logout');
      router.push('/login');
    }

    return Promise.reject(error);
  } 
);

const updateToken = () => {
  return new Promise ((resolve, reject) => {
    const refreshToken = Cookies.get('refreshToken');
    axios.post(base + "api/token/refresh/", {refresh: refreshToken}).then( response => {
      Cookies.set('accessToken', response.data.access)
      Cookies.set('refreshToken', response.data.refresh)
      return resolve(response.data.access);
    }).catch( (error) => {
      if (error.response.data.detail === "Token is invalid or expired") {
        store.dispatch('user/logout');
        router.push('/login');
      }
    })
  })
}

export default api; 
