import request from '../base';

export function Login(data, headers) {
  return request({
    url: 'login_web/',
    method: 'post',
    data,
    headers
  })
}

export function UserInformation(data) {
  return request({
    url: 'administrative/user_information/',
    method: 'get',
    params: data
  })
}

export function UpdateUserInformation(userId, data) {
  return request({
    url: `administrative/user_information/${userId}/`,
    method: 'patch',
    data,
  })
}

export function TemplatesInformation() {
  return request({
    url: 'administrative/templates/',
    method: 'get',
  })
}

export function SendEmail(data, params) {
  return request({
    url: 'administrative/send_email/',
    method: 'post',
    data,
    params: params
  })
}

export function GetInformationToExportToCSV(data) {
  return request({
    url: 'administrative/user_information/csv_information',
    method: 'get',
    params: data
  })
}

export function GetUserTags(param) {
  return request({
    url: `administrative/tag_types/?user_id=${param}`,
    method: 'get',
  })
}

export function GetUserOrganizations(param) {
  return request({
    url: `administrative/user_organizations/?user_id=${param}`,
    method: 'get',
  })
}

export function GetUserTypes(param) {
  return request({
    url: `administrative/user_types/?user_id=${param}`,
    method: 'get',
  })
}

export function GetOrganizations() {
  return request({
    url: 'organizations',
    method: 'get',
  })
}

export function RequestPasswordReset(data) {
  return request({
    url: 'reset_password/?from_web=yes',
    method: 'post',
    data,
  })
}

export function GetPasswordResetToken(userId) {
  return request({
    url: `reset_password_token/${userId}/`,
    method: 'get',
  })
}

export function SetPasswordReset(data, headers) {
  return request({
    url: 'reset_password/change/?from_web=true',
    method: 'post',
    data,
    headers,
  })
}

export function RefreshToken(data) {
  return request({
    url: 'api/token/refresh/',
    method: 'post',
    data,
  })
}
