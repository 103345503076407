import {Login, RefreshToken, UserInformation} from '../../api/user';
import Cookies from 'js-cookie';

export default {
  namespaced: true,

  state: {
    isAuthenticated: false,
    accessToken: Cookies.get('accessToken'),
    refreshToken: Cookies.get('refreshToken'),
    userEmail: "",
    userOrganization: "",
    isSuperuser: false,
    userRole: "",
    userInformation: [],
    userFilters: {}
  },

  getters: {
    getIsAuthenticated (state) {
      state.isAuthenticated = Cookies.get('isAuthenticated') ? true : false;
      return state.isAuthenticated;
    },

    getAccessToken (state) {
      return state.accessToken;
    },

    getRefreshToken (state) {
      state.refreshToken = Cookies.get('refreshToken');
      return state.refreshToken
    },

    getUserEmail (state) {
      state.userEmail = Cookies.get('userEmail')
      return state.userEmail
    },

    getUserOrganization (state) {
      state.userOrganization = Cookies.get('userOrganization')
      return state.userOrganization
    },

    getUserIsSuperuser (state) {
      state.isSuperuser = Cookies.get('isSuperuser')
      return state.isSuperuser
    },

    getUserRole (state) {
      state.userRole = Cookies.get('userRole')
      return state.userRole
    },

    getUserInformation (state) {
      return state.userInformation
    },

    getUserFilters (state) {
      return state.userFilters
    }
  },

  mutations: {
    setAccessToken (state, token) {
      Cookies.set('accessToken', token);
      state.accessToken = token;
    },
    
    setRefreshToken (state, token) {
      Cookies.set('refreshToken', token);
      state.refreshToken = token;
    },

    setIsAuthenticated (state, isAuthenticated) {
      Cookies.set('isAuthenticated', isAuthenticated);
      state.isAuthenticated = isAuthenticated;
    },

    setUserInformation (state, userInformation) {
      state.userInformation = userInformation;
    },

    setUserEmail (state, userEmail) {
      Cookies.set('userEmail', userEmail);
      state.userEmail = userEmail;
    },

    setUserOrganization (state, userOrganization) {
      Cookies.set('userOrganization', userOrganization);
      state.userOrganization = userOrganization;
    },

    setUserIsSuperuser (state, isSuperuser) {
      Cookies.set('isSuperuser', isSuperuser);
      state.isSuperuser = isSuperuser;
    },

    setUserRole (state, userRole) {
      Cookies.set('userRole', userRole);
      state.userRole = userRole;
    },

    setUserFilters (state, userFilters) {
      state.userFilters = userFilters;
    },

    setLogout (state) {
      // Cookies.keys().forEach( cookie => {
      //   Cookies.remove(cookie, "/", "");
      // });
      Cookies.remove("isAuthenticated");
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      Cookies.remove("userEmail");
      Cookies.remove("userOrganization");
      Cookies.remove("isSuperuser");
      Cookies.remove("userRole");
      state.accessToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      state.userEmail = ""
      state.userOrganization = ""
      state.isSuperuser = false
      state.userRole = ""
    }
  },

  actions: {
    handleLogin ({ commit }, userCredentials) {
      const { email, password } = userCredentials;
      userCredentials = email + ":" + password;
      const userCredentialsBase64Encrypted = btoa(userCredentials);
      const headers = {
        'Authorization': 'Basic ' + userCredentialsBase64Encrypted,
        'Content-Type': 'application/json'
      }
      return new Promise((resolve, reject) => {
        Login( {}, headers ).then(response => {
          if (response.status === 200) {
            const { access, refresh } = response.data;
            const { email, is_superuser, organization, role } = response.data.user_data
            commit('setAccessToken', access);
            commit('setRefreshToken', refresh);
            commit('setIsAuthenticated', true);
            commit('setUserEmail', email);
            commit('setUserOrganization', organization);
            commit('setUserIsSuperuser', is_superuser);
            commit('setUserRole', role);
            resolve();
          }
        }).catch(error => {
          reject(error);
        })
      })
    },

    refreshToken ({ commit }, refreshToken) {
      const data = {
        refresh: refreshToken
      }
      return new Promise((resolve, reject) => {
        RefreshToken(data).then(response => {
          const { access, refresh } = response.data;
          commit('setAccessToken', access);
          commit('setRefreshToken', refresh);
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      })
    },

    userInformation ({ commit }, params) {
      return new Promise((resolve, reject) => {
        UserInformation(params).then( response => {
          commit('setUserInformation', response.data);
          resolve(response.data);
        })
      })
    },

    logout ({ commit }) {
      let data = {}
      commit('setLogout');
      return data
    }
  },
}
