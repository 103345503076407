import request from '../base';

export function GetProfessions(data) {
  return request({
    url: 'administrative/professions/',
    method: 'get',
    params: data
  })
}

export function GetSpecialties(data) {
  return request({
    url: 'administrative/specialties/',
    method: 'get',
    params: data
  })
}

export function GetTagTypes(data) {
  return request({
    url: 'administrative/tag_types/active_tags/',
    method: 'get',
    params: data
  })
}
