<template>
  <div>
    <alert
      v-if="sentEmailAlert"
      :vModel.sync="sentEmailAlert"
      :description="alertText"
      :disabled="true"
    ></alert>
    <v-dialog
      v-model="emailMenu"
      width="900"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="text-none"
          color="#013149"
          dark
          v-bind="attrs"
          v-on="on"
          @click="getTemplates"
        >
          <v-icon medium left>
            mdi-email-outline
          </v-icon>
          Send an email
        </v-btn>
      </template>
      <v-card color="grey lighten-3">
        <v-container
          fluid
          color="grey lighten-3"
        >
          <v-row
            class="pa-1"
            align="center"
          >
            <v-col
              class="d-flex flex-column"
              cols="12"
              sm="7"
              style="height: 300;"
            >
              <v-card-title style="color: grey">
                Email templates
              </v-card-title>
              <v-sheet
                class="templates-container"
                color="grey lighten-3"
                height="400"
              >
                <v-container
                  fluid
                >
                  <v-row
                    v-for="template in emailTemplates"
                    :key="template.id"
                    class="pa-1"
                    align="start"
                  >
                    <v-col
                      class="d-flex"
                      cols="12"
                      sm="5"
                    >
                      <v-img
                        max-width="200"
                        max-height="200"
                        :src="template.image"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                    <v-col
                      class="d-flex flex-column"
                      cols="12"
                      sm="7"
                    >
                      <v-card-title style="color: grey">
                        {{ template.title }}
                      </v-card-title>
                      <v-card-text>
                        {{ template.description }} 
                      </v-card-text>
                      <v-card-actions class="mt-4">
                        <v-btn
                          class="text-none ml-2"
                          width="40%"
                          dark
                          color="#013149"
                          @click="applyTemplate(template)"
                        >
                          Apply
                        </v-btn>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
            </v-col>
            <v-col
              class="d-flex flex-column"
              cols="12"
              sm="5"
            >
              <v-card-title style="color: grey">
                Email preview image
              </v-card-title>
              <v-sheet
                class="d-flex"
                color="grey lighten-3"
                height="400"
              >
                <v-img
                  v-if="templateImage"
                  :src="templateImage"
                ></v-img>
                <h5
                  v-else
                  class="align-self-center ml-auto mr-auto"
                >
                  Select an image to preview
                </h5>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
        <!-- <v-divider></v-divider> -->
        <v-card-actions class="justify-center">
          <!-- <v-spacer></v-spacer> -->
          <v-btn
            class="text-none ml-2"
            width="80"
            dense
            dark
            color="#6B6F6B"
            @click="emailMenu = false"
          >
            Cancel
          </v-btn>
          <!-- <v-spacer></v-spacer> -->
          <v-btn
            class="text-none ml-2"
            width="80"
            dense
            dark
            color="primary"
            :disabled="templateImage ? false : true"
            :loading="loading"
            @click="sendEmail"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import {userParameters} from '../../../utils/userParameters.js'
import Alert from '../../../components/Alert.vue'

import {
  SendEmail, 
  TemplatesInformation, 
} from '../../../api/user';

export default {

  components: {
    Alert,
  },
  
  props: {
    selectedUsers: {
      type: Array,
      default: () => []
    },
    searchInput: String,
    selectAll:Boolean,
  },

  data () {
    return {
      loading: false,
      emailMenu: false,
      emailTemplates: [],
      templateImage: null,
      selectedTemplate: "",
      templateImage: "",
      emailSent: false,
      sentEmailAlert: false,
      alertText: "",
    }
  },

  methods: {

    getTemplates () {
      this.templateImage = ""
      this.selectedTemplate = ""

      TemplatesInformation().then( response => {
        this.emailTemplates = response.data
      })
    },

    applyTemplate (template) {
      this.templateImage = template.image
      this.selectedTemplate = template.template_id
    },

    sendEmail () {
      this.loading = true

      let data = {}
      let params = {}
      if (this.selectAll === true) {
        params = userParameters(this.userFilters)
        params["organization"] = this.userOrganization
        params["search_bar"] = this.searchInput

        data = {
          select_all: true,
          template_id: this.selectedTemplate,
        }
      } else {
        data = {
          select_all: false,
          users: this.selectedUsers,
          template_id: this.selectedTemplate,
        }
      }

      SendEmail(data, params).then( () => {
        this.sentEmailAlert = true
        this.alertText = "Email sent successfully"
        this.emailSent = true
        this.loading = false
      }).catch( () => {
        this.sentEmailAlert = true
        this.alertText = "Email sending failed, please check that the email template is correct"
        this.emailSent = false
        this.loading = false
      })
    },
  },

  computed: {
    ...mapGetters({
      userOrganization: "user/getUserOrganization",
      userRole: "user/getUserRole",
      userFilters: "user/getUserFilters"
    }),
  }
}
</script>
